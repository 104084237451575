// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-previous-events-js": () => import("./../src/pages/previous-events.js" /* webpackChunkName: "component---src-pages-previous-events-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-templates-news-list-js": () => import("./../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-news-post-js": () => import("./../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */)
}

